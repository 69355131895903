<template>
  <div
    v-if="inventory.length"
    class="grow d-grid flex-column px-0 mx-0"
  >
    <VRow class="shrink ma-0">
      <VCol cols="3">
        <VAutocomplete
          v-model="measurement"
          :placeholder="$t('measurements')"
          :items="measurementsOrForecast"
          :item-disabled="isDisabled"
          dark
          dense
          class="pt-4"
          @change="onMeasurementChange"
        />
      </VCol>

      <VCol cols="2">
        <VSwitch
          v-model="enableForecast"
          inset
          :label="$t('grid_monitor.forecast')"
        />
      </VCol>

      <VCol
        offset="3"
        cols="2"
      >
        <CMDatePicker
          identifier="start"
          :label="$t('grid_monitor.date_start')"
          @updateDate="updateDate"
        />
      </VCol>

      <VCol cols="2">
        <CMDatePicker
          identifier="end"
          :min="timeSelection.start || ''"
          :label="$t('grid_monitor.date_end')"
          left="150"
          @updateDate="updateDate"
        />
      </VCol>
    </VRow>

    <VRow class="grow ma-0">
      <VCol
        cols="12"
        class="ma-0 py-0"
      >
        <CMTable
          has-disabling
          show-select
          origin="measurements.inventory"
          :page-size="12"
          :selected-from-parent="selectedFromParent"
          :header-config="{
            TYPE: { advanced: 'numeric' },
            SAMPLING_TIME: { advanced: 'numeric' },
            INSTALLATION_DATE: { advanced: 'date' },
            STATION: { advanced: 'numeric' },
            TRANSFORMER: { advanced: 'numeric' },
            FEEDER: { advanced: 'numeric' },
            CGP: { advanced: 'numeric' },
            PHASE: { advanced: 'numeric' }
          }"
          :hidden-cols="['isDisabled']"
          :disabling-condition="disablingCondition"
          :custom-title="headerTranslations"
          :show-sync-map-btn="true"
          @onToggleSelectAll="onToggleSelectAll"
          @onItemSelected="updateSelected"
        />
      </VCol>
    </VRow>

    <VRow class="shrink bottom-section">
      <VCol
        v-if="feedback.visible"
        cols="12"
        class="p-feedback"
      >
        <VAlert
          v-if="feedback.visible"
          dense
          :type="feedback.type"
          elevation="5"
          transition="slide-y-transition"
        >
          {{ feedback.message }}
        </VAlert>
      </VCol>

      <VCol cols="2">
        <VTextField
          v-model="aggregation"
          :label="$t('grid_monitor.aggregation')"
          :hint="$t('grid_monitor.aggregation_name')"
          clearable
        />
      </VCol>

      <VCol cols="2">
        <VAutocomplete
          v-model="operator"
          :label="$t('grid_monitor.operator')"
          :disabled="!selected.length"
          :items="operators"
          dark
          dense
          class="pt-4"
          style="z-index: 500;"
        />
      </VCol>

      <VCol
        cols="2"
        class="d-flex"
      >
        <VBtn
          color="primary black--text"
          :disabled="disabledSendToPlot"
          @click="sendToPlot"
        >
          {{ loading ?$t('grid_monitor.sending') : $t('grid_monitor.send_to_plot') }}
        </VBtn>
      </VCol>
    </VRow>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getMeasurements, getPlotData } from '@/services/measurements'
import { getForecastMeasurements, getForecastPlotData } from '@/services/forecast'
import MSTableMixin from '@/mixins/Measurements/MSTableMixin'
import mapElementDisableMixin from '@/mixins/map/mapElementDisableMixin'
import vuexMixin from '@/mixins/vuexMixin'
import MSCommonTabs from '@/mixins/Measurements/MSCommonTabs'

export default {
  name: 'MSMeasurementsTab',

  mixins: [MSTableMixin, vuexMixin, mapElementDisableMixin, MSCommonTabs],

  data: () => ({
    measurement: null,
    operator: null,
    measurements: [],
    forecastMeasurements: [],
    disabledItemCount: 0,
    selectedFromParent: [],
    enableForecast: false
  }),

  computed: {
    ...mapState({
      metersCodeMap: ({ measurements }) => measurements.metersCodeMap,
      metersCodeMapTypes: ({ measurements }) => measurements.metersCodeMapTypes
    }),

    measurementsOrForecast () {
      return this.enableForecast
        ? this.forecastMeasurements
        : this.measurements
    },

    isSupervisor () {
      return ['AS', 'SU']
        .includes(this.currentSelector.short)
    },

    selectedIds () {
      return this.selected.map(item => item.ID)
    },

    disabledSendToPlot () {
      const anyDateEmpty = Object.values(this.timeSelection).some(time => !time)

      return !this.measurement ||
        !this.selected.length ||
        !this.operator ||
        !this.aggregation ||
        anyDateEmpty
    },

    headerTranslations () {
      return [
        { value: 'NAME', text: 'name' },
        { value: 'TYPE', text: 'type' },
        { value: 'SAMPLING_TIME', text: 'grid_monitor.sampling_time' },
        { value: 'INSTALLATION_DATE', text: 'installation_date' },
        { value: 'STATION', text: 'grid.station' },
        { value: 'FEEDER', text: 'grid.feeder' },
        { value: 'CGP', text: 'grid.connection_point' },
        { value: 'PHASE', text: 'phase' },
        { value: 'TRANSFORMER', text: 'grid.transformer' }
      ]
    },

    operators () {
      const types = [
        'NONE',
        'MEAN',
        'SUM',
        'MEDIAN',
        'COUNT',
        'MIN',
        'MAX',
        'FIRST',
        'LAST',
        'SPREAD',
        'STDDEV'
      ]

      const selectedLength = this.selected.length

      return types.map(type => {
        const isTypeNone = type === 'NONE'

        return {
          text: type,
          value: type,
          disabled: (selectedLength === 1 && !isTypeNone) ||
            (selectedLength > 1 && isTypeNone && this.enableForecast)
        }
      })
    },

    isDisabled (item) {
      return item.disabled
    },

    disablingCondition () {
      return this.setDisablingCondition({
        metersCodeMap: this.metersCodeMap,
        enableForecast: this.enableForecast,
        measurement: this.measurement,
        currentTab: this.currentTab
      })
    }
  },

  watch: {
    metersCodeMapTypes () {
      this.updateMeasurements()
    },

    measurement (val) {
      this.setVuexElement({
        path: 'measurements.selectedMeasurementId',
        value: val
      })
    }
  },

  async mounted () {
    this.measurements = await getMeasurements()
    this.forecastMeasurements = await getForecastMeasurements()

    this.enableMeasurements('measurements')
    this.enableMeasurements('forecastMeasurements')
  },

  methods: {
    async setPlotData () {
      this.toggleLoading()

      const commonProps = {
        measurement: this.measurement,
        aggregation: this.operator,
        isSupervisor: this.isSupervisor,
        meters: this.selectedIds,
        timeStart: this.timeSelection.start,
        timeEnd: this.timeSelection.end
      }

      const requestData = this.enableForecast
        ? { ...commonProps, isSupervisor: this.isSupervisor }
        : commonProps

      const response = await (this.enableForecast
        ? getForecastPlotData(requestData)
        : getPlotData(requestData))

      const { aggregation } = commonProps
      const responseAggregation = response[aggregation] || []

      if (responseAggregation) {
        const isAggNone = aggregation === 'NONE'

        const verifyNotNoneFlow = () => {
          const { time, value } = responseAggregation
          const hasNotTimeOrValue = !time.length || !value.length
          const emptyResponseAgg = !Object.keys(responseAggregation).length

          if (emptyResponseAgg || hasNotTimeOrValue) {
            response.error = true
          }
        }

        const verifyNoneFlow = () => {
          const aggWithTimeValue = responseAggregation
            .filter(element => (element.time?.length && element.value?.length))

          response[aggregation] = aggWithTimeValue

          if (!aggWithTimeValue.length) {
            response.error = true
          }
        }

        isAggNone
          ? verifyNoneFlow()
          : verifyNotNoneFlow()
      }

      if (response.error) {
        this.setVuexElement({
          path: 'measurements.feedback',
          value: {
            type: 'error',
            message: 'No data found with parameters entered',
            visible: true
          }
        })

        this.toggleLoading()
      }

      return response
    },

    async sendToPlot () {
      const plotData = await this.setPlotData()

      if (!plotData.error) {
        const dataOperator = plotData[this.operator]

        const currentMeasurement = this.measurements
          .find(measurement => measurement.value === this.measurement)

        const commonProps = {
          selectedElementIds: this.selectedIds,
          measurement: this.measurement,
          operator: this.operator,
          timeSelection: this.timeSelection,
          units: currentMeasurement?.units,
          origin: 'backend',
          selector: this.currentSelector.title,
          tab: 'measurements'
        }

        this.setVuexElement({
          path: 'measurements.plotData',
          value: [
            ...this.plotData,
            ...(this.operator !== 'NONE'
              ? [{
                  data: dataOperator,
                  aggregation: this.aggregation,
                  meters: plotData.meters || plotData[0].meter,
                  ...commonProps
                }]
              : dataOperator.map(({ meter, ...restData }) => ({
                data: restData,
                aggregation: `${this.aggregation}-${meter}`,
                meters: [meter],
                ...commonProps
              }))
            )
          ]
        })

        this.aggregation = ''
        this.operator = null

        this.clearTimeSelection()
        this.toggleLoading()
        this.clearSelection()
      }
    },

    onMeasurementChange () {
      const metersCodeMapEntries = Object.entries(this.metersCodeMap.MEASUREMENTS)

      const types = metersCodeMapEntries
        .map(([type, value]) => value.includes(this.measurement) && Number(type))
        .filter(Boolean)

      this.setVuexElement({
        path: 'measurements.metersCodeMapTypes',
        value: types
      })
    },

    updateMeasurements () {
      if (this.metersCodeMapTypes.length && this.origin) {
        const codeMapEntries = Object.entries(this.metersCodeMap.MEASUREMENTS)

        const codeMapEntriesTypes = codeMapEntries
          .filter(([key]) => this.metersCodeMapTypes.includes(Number(key)))

        const codeMapValues = Object.values(Object.fromEntries(codeMapEntriesTypes))
        const initialSetValue = new Set(Object.values(codeMapValues.at(0)))

        const codeMapValuesSet = codeMapValues.reduce((setValues, next) => {
          return new Set(next.filter(i => setValues.has(i)))
        }, initialSetValue)

        this.measurements.map(measurement => {
          measurement.disabled = ![...codeMapValuesSet].includes(measurement.value)
          return measurement
        })

        return this.measurements
      }

      this.enableMeasurements('measurements')
    },

    enableMeasurements (measurementsKey) {
      this[measurementsKey] = this[measurementsKey]
        ?.map(measurement => ({
          ...measurement,
          disabled: false
        }))
    },

    setDisablingCondition ({
      metersCodeMap = {},
      enableForecast,
      measurement,
      currentTab = ''
    }) {
      const currentTypes = enableForecast
        ? metersCodeMap.FORECASTS
        : metersCodeMap[currentTab.toUpperCase()]

      return (item = {}) =>
        currentTypes &&
        !currentTypes[item.TYPE].includes(measurement)
    }
  }
}
</script>

<style scoped>
::v-deep .v-menu__content {
  z-index: 503 !important;
}

::v-deep .v-text-field.v-input--dense .v-label {
  top: 0 !important
}

.p-feedback {
  padding: 20px 100px;
}

.bottom-section {
  margin: 0;
  display: flex;
  align-items: center;
}
</style>
